<template>
  <svg
    viewBox="0 0 205 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="$attrs.class"
  >
    <path
      d="M0.170868 20.1092H1.81548L1.98101 20.2618C2.15416 22.1009 3.49975 23.2146 5.87589 23.2146C8.33975 23.2146 9.67161 22.3267 9.67161 20.5066C9.67161 18.9971 8.58385 18.3311 6.89729 17.9764L4.63328 17.4882C1.94744 16.9115 0.504976 15.6682 0.504976 13.2928C0.504976 10.6741 2.45852 9.03101 5.89877 9.03101C9.15748 9.03101 11.3101 10.8167 11.4215 13.4728L11.2514 13.6475H9.60296L9.43743 13.4949C9.29021 11.893 8.17575 10.8083 5.85224 10.8083C3.85445 10.8083 2.5226 11.5406 2.5226 13.2943C2.5226 14.5598 3.52187 15.2479 5.16419 15.6026L7.27259 16.0465C10.1583 16.6461 11.6892 18.1328 11.6892 20.5082C11.6892 23.2825 9.46947 24.9691 5.8736 24.9691C2.17704 24.9691 0.164003 23.1162 0 20.2862L0.170868 20.1092Z"
      fill="black"
    />
    <path
      d="M14.9127 10.841V9.40085L15.0805 9.23303H23.0267L23.1945 9.40085V10.841L23.0267 11.0088H20.2081L20.0403 11.1767V18.2082L19.8725 18.376H18.2546L18.0868 18.2082V11.1767L17.9189 11.0088H15.0821L14.9127 10.841Z"
      fill="black"
    />
    <path
      d="M16.2 22.9872L16.0322 23.155V24.5952L16.2 24.763H22.1431L22.3109 24.5952V23.1535L22.1431 22.9857L16.2 22.9872Z"
      fill="black"
    />
    <path
      d="M29.9424 16.8459H33.8807C35.8335 16.8459 36.8992 15.778 36.8992 13.916C36.8992 12.0959 35.8312 11.0082 33.8807 11.0082H29.9424L29.7746 11.176V16.6743L29.9424 16.8459ZM33.4581 18.621H29.9424L29.7746 18.7888V24.6014L29.6067 24.7692H28.011L27.8431 24.6014V9.40018L28.011 9.23236H33.9914C37.1654 9.23236 38.9191 11.2523 38.9191 13.8939C38.9612 14.874 38.6757 15.8402 38.1078 16.6401C37.5398 17.44 36.7216 18.028 35.7824 18.3113L35.6932 18.5638L39.7398 24.548V24.77H37.723L37.5834 24.6937L33.5985 18.695L33.4581 18.621Z"
      fill="black"
    />
    <path
      d="M44.482 9.23303L44.3142 9.40085V24.6028L44.482 24.7706H54.1346L54.3024 24.6028V23.1847L54.1346 23.0169H46.4135L46.2456 22.8491V17.8146L46.4135 17.6468H53.4473L53.6151 17.479V16.0388L53.4473 15.871H46.4135L46.2456 15.7031V11.1782L46.4135 11.0104H54.1346L54.3024 10.8426V9.40238L54.1346 9.23456L44.482 9.23303Z"
      fill="black"
    />
    <path
      d="M59.2272 9.23303L59.0594 9.40085V24.6028L59.2272 24.7706H68.8798L69.0476 24.6028V23.1847L68.8798 23.0169H61.1579L60.9901 22.8491V17.8146L61.1579 17.6468H68.191L68.3588 17.479V16.0388L68.191 15.871H61.1579L60.9901 15.7031V11.1782L61.1579 11.0104H68.879L69.0468 10.8426V9.40238L68.879 9.23456L59.2272 9.23303Z"
      fill="black"
    />
    <path
      d="M72.6322 9.23303L72.4644 9.40085V10.841L72.6322 11.0088H77.1129L77.2807 11.1767V24.602L77.4485 24.7699H79.0665L79.2343 24.602V11.1767L79.4021 11.0088H83.8828L84.0506 10.841V9.40085L83.8828 9.23303H72.6322Z"
      fill="black"
    />
    <line
      x1="97.2243"
      y1="0.981079"
      x2="97.2243"
      y2="33.0189"
      stroke="black"
      stroke-width="0.762806"
    />
    <path
      d="M112.152 10.5161V23.4838H109.635V10.5161H112.152Z"
      class="fill-current text-primary-600"
    />
    <path
      d="M127.483 10.5161V23.4838H125.214L118.444 14.3302H118.367L118.463 23.4838H116.079V10.5161H118.367L125.137 19.5745H125.195L125.118 10.5161H127.483Z"
      class="fill-current text-primary-600"
    />
    <path
      d="M135.831 10.3636C137.331 10.3636 138.564 10.745 139.531 11.5078C140.497 12.2579 141.056 13.2813 141.209 14.5781H138.768C138.59 13.917 138.234 13.4085 137.7 13.0525C137.166 12.6838 136.498 12.4994 135.697 12.4994C134.922 12.4994 134.299 12.6329 133.829 12.8999C133.371 13.1669 133.142 13.5292 133.142 13.9869C133.142 14.4446 133.371 14.8006 133.829 15.0548C134.286 15.3091 135.138 15.5252 136.384 15.7032C138.164 15.9448 139.435 16.3707 140.198 16.9809C140.961 17.5785 141.342 18.4175 141.342 19.4982C141.342 20.7695 140.846 21.7802 139.855 22.5303C138.863 23.2677 137.554 23.6364 135.926 23.6364C134.363 23.6364 133.091 23.2614 132.112 22.5113C131.146 21.7612 130.618 20.7187 130.529 19.3838H132.99C133.117 20.0194 133.454 20.5343 134 20.9284C134.56 21.3098 135.246 21.5005 136.06 21.5005C136.886 21.5005 137.547 21.348 138.043 21.0429C138.552 20.725 138.806 20.2991 138.806 19.7652C138.806 19.2439 138.571 18.8434 138.1 18.5637C137.643 18.2713 136.683 18.0234 135.221 17.82C133.555 17.5912 132.373 17.2034 131.674 16.6567C130.974 16.1101 130.625 15.3409 130.625 14.3492C130.625 13.1542 131.102 12.1943 132.055 11.4697C133.009 10.7323 134.267 10.3636 135.831 10.3636Z"
      class="fill-current text-primary-600"
    />
    <path
      d="M146.924 10.5161V23.4838H144.407V10.5161H146.924Z"
      class="fill-current text-primary-600"
    />
    <path
      d="M157.182 21.348C158.136 21.348 158.968 21.1128 159.68 20.6424C160.392 20.1593 160.888 19.5045 161.168 18.6782H157.201V16.6758H163.971C163.971 18.6464 163.329 20.2991 162.045 21.634C160.774 22.9689 159.153 23.6364 157.182 23.6364C155.262 23.6364 153.648 23.0071 152.338 21.7485C151.041 20.4898 150.393 18.9197 150.393 17.0381C150.393 15.1438 151.048 13.561 152.357 12.2897C153.667 11.0056 155.275 10.3636 157.182 10.3636C158.619 10.3636 159.915 10.7513 161.072 11.5269C162.229 12.2897 163.049 13.3067 163.532 14.5781H160.71C160.329 13.9678 159.826 13.4974 159.203 13.1669C158.593 12.8236 157.919 12.652 157.182 12.652C156.012 12.652 155.021 13.0715 154.207 13.9106C153.406 14.737 153.006 15.7668 153.006 17C153.006 18.2332 153.406 19.2693 154.207 20.1084C155.008 20.9348 156 21.348 157.182 21.348Z"
      class="fill-current text-primary-600"
    />
    <path
      d="M178.071 10.5161V23.4838H175.554V17.9916H169.776V23.4838H167.259V10.5161H169.776V15.8176H175.554V10.5161H178.071Z"
      class="fill-current text-primary-600"
    />
    <path
      d="M187.826 12.6901V23.4838H185.309V12.6901H181.037V10.5161H192.098V12.6901H187.826Z"
      class="fill-current text-primary-600"
    />
    <path
      d="M199.489 10.3636C200.989 10.3636 202.222 10.745 203.188 11.5078C204.155 12.2579 204.714 13.2813 204.867 14.5781H202.426C202.248 13.917 201.892 13.4085 201.358 13.0525C200.824 12.6838 200.156 12.4994 199.355 12.4994C198.58 12.4994 197.957 12.6329 197.486 12.8999C197.029 13.1669 196.8 13.5292 196.8 13.9869C196.8 14.4446 197.029 14.8006 197.486 15.0548C197.944 15.3091 198.796 15.5252 200.042 15.7032C201.822 15.9448 203.093 16.3707 203.856 16.9809C204.619 17.5785 205 18.4175 205 19.4982C205 20.7695 204.504 21.7802 203.513 22.5303C202.521 23.2677 201.211 23.6364 199.584 23.6364C198.02 23.6364 196.749 23.2614 195.77 22.5113C194.804 21.7612 194.276 20.7187 194.187 19.3838H196.647C196.774 20.0194 197.111 20.5343 197.658 20.9284C198.217 21.3098 198.904 21.5005 199.718 21.5005C200.544 21.5005 201.205 21.348 201.701 21.0429C202.209 20.725 202.464 20.2991 202.464 19.7652C202.464 19.2439 202.228 18.8434 201.758 18.5637C201.3 18.2713 200.341 18.0234 198.878 17.82C197.213 17.5912 196.031 17.2034 195.331 16.6567C194.632 16.1101 194.283 15.3409 194.283 14.3492C194.283 13.1542 194.759 12.1943 195.713 11.4697C196.666 10.7323 197.925 10.3636 199.489 10.3636Z"
      class="fill-current text-primary-600"
    />
  </svg>
</template>

<script setup></script>
